<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light-2.jpg') + ')'}">
      <div class="container space-3 mt-md-3 mb-md-3 position-relative">
        <!-- Title -->
        <div class="w-100 w-md-70 w-lg-75 mx-auto text-center">
          <div>
            <span class="d-block small font-weight-bold text-cap mb-3">Apidays Hong Kong</span>
            <!-- <img class="img-fluid mb-6" src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" style="max-width:18rem"> -->
          </div>
          <h1>The Largest API Ecosystem Conference Series in Hong Kong</h1>
        </div>
      </div>
      <!-- SVG Background -->
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
      <!-- End SVG Background -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Video ========== -->
    <div class="container position-relative z-index-2 space-bottom-1">
      <div class="w-lg-65 mx-lg-auto">
        <div class="card mt-n11 mx-auto">
          <div class="card-body p-2 text-center">
            <div id="VideoPlayer" class="video-player" style="background-color:#f7faff">
              <img class="img-fluid video-player-preview" src="@/assets/img/apidays-2023/images/video-cover.jpg">
              <a
                class="js-inline-video-player video-player-btn video-player-centered"
                href="javascript:;"
                data-hs-video-player-options="{
                    &quot;videoType&quot;: &quot;you-tube&quot;,
                    &quot;videoId&quot;: &quot;2r0MFhr2bj4&quot;,
                    &quot;parentSelector&quot;: &quot;#VideoPlayer&quot;,
                    &quot;targetSelector&quot;: &quot;#VideoIframe&quot;,
                    &quot;isAutoplay&quot;: true,
                    &quot;isMuted&quot;: false,
                    &quot;classMap&quot;: {
                    &quot;toggle&quot;: &quot;video-player-played&quot;
                    }
                    }"
              >
                <span class="video-player-icon video-player-icon-white video-player-icon-pill">
                  <i class="fas fa-play-circle mr-2 font-size-2" /> Watch 2023 Highlight
                </span>
              </a>
              <div class="embed-responsive embed-responsive-16by9">
                <div id="VideoIframe" />
              </div>
            </div>
          </div>
        </div>
        <p class="small text-center mt-2">Apidays Connect Hong Kong 2023 Event Highlight</p>
      </div>
    </div>
    <!-- ========== End Video ========== -->

    <!-- ========== About Section========== -->
    <div class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-6 mb-3 mb-lg-0">
          <h2 class="mb-3">About Apidays Hong Kong</h2>
          <p>Apidays Hong Kong is the largest API Ecosystem Conference series in the city since 2020. In this API conference series, attendees can learn about the latest API applications, network with industry experts, and gain insights into Hong Kong's tech ecosystem, data-driven technologies, fintech, generative AI, and the financial industry.</p>
        </div>
        <div class="col-lg-6">
          <!-- <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2021/images/hongkong.jpg') + ')'}" /> -->
          <div class="row">
            <div class="col-6 mb-3 mb-md-5">
              <div class="pr-lg-4">
                <span class="h2 text-primary">3000</span>
                <span class="h2 text-primary">+</span>
                <p>attendees all over the globe</p>
              </div>
            </div>

            <div class="col-6 mb-3 mb-md-5">
              <div class="pr-lg-4">
                <span class="h2 text-primary">100</span>
                <span class="h2 text-primary">+</span>
                <p>companies in different sectors</p>
              </div>
            </div>

            <div class="col-6">
              <div class="pr-lg-4">
                <span class="h2 text-primary">100</span>
                <span class="h2 text-primary">+</span>
                <p>sessions about various topics on Open API Economy</p>
              </div>
            </div>

            <div class="col-6">
              <div class="pr-lg-4">
                <span class="h2 text-primary">200</span>
                <span class="h2 text-primary">+</span>
                <p>speakers from different professional roles</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section========== -->

    <!-- ========== 2023 Highlight ========== -->
    <div class="clearfix container space-2 space-top-lg-1 space-bottom-lg-3">
      <div class="bg-img-hero rounded" :style="{'background-image': 'url(' + require('@/assets/img/apidays-2023/images/day-1/onsite-1.jpg') + ')'}">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 space-2">
              <div class="card">
                <div class="card-body text-center p-5">
                  <img class="img-fluid max-w-19rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                  <h3>Interested in Getting a Taste of Our Event?</h3>
                  <p>The full replay of Day 1 & Day 2 is now available! Register below to gain access and start watching it immediately. Don't miss out on this opportunity!</p>
                  <!-- Begin Mailchimp Signup Form -->
                  <div id="mc_embed_signup">
                    <form id="mc-embedded-subscribe-form" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=002b9fe6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                      <div class="row">
                        <div class="col col-md-6">
                          <!-- First name -->
                          <div class="mc-field-group w-100">
                            <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                            </label>
                            <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                          </div>
                        </div>
                        <div class="col col-md-6">
                          <!-- Last name -->
                          <div class="mc-field-group w-100">
                            <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                            </label>
                            <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                          </div>
                        </div>
                      </div>
                      <!-- Email -->
                      <div class="mc-field-group w-100">
                        <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                        </label>
                        <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                      </div>
                      <div class="mb-4">
                        <p class="small text-center">By submitting your information, you agree to receive future communications from apidays.hk and OpenAPIHub by beNovelty.</p>
                      </div>
                      <div hidden=""><input type="hidden" name="tags" value="2985272"></div>
                      <div id="mce-responses" class="clear">
                        <div id="mce-error-response" class="response" style="display: none;" />
                        <div id="mce-success-response" class="response" style="display: none;" />
                      </div>
                      <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                      <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                      <div class="clear"><input id="mc-embedded-subscribe" type="submit" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0" value="Subscribe"></div>
                    </form>
                  </div>
                  <!--End mc_embed_signup-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End 2023 Highlight ========== -->

    <!-- ========== Past year Section ========== -->
    <div style="background-color:#f5f9fc;">
      <div class="container space-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">A Look Back at the Past Conferences</h2>
          <p>Thanks for all your support to Apidays Live Hong Kong 2020-2023.<br>Let's find out what happened in the past few years.</p>
        </div>

        <div class="w-lg-80 mx-auto mt-8 mb-11">
          <div class="card overflow-hidden p-5 bg-primary">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h3 class="mb-0 text-white">Interested to View More Replay Videos of Our Past Events?</h3>
              </div>
              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="" class="btn btn-light transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Watch Now</a>
              </div>
            </div>
            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2.svg">
            </figure>
          </div>
        </div>

        <div class="row mx-auto">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong-2022" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2022.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2022</h4>
                  <p class="font-size-1">API-First Digital Transformation & Platform Economy</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong-2021" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2021.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2021</h4>
                  <p class="font-size-1">API Ecosystem &amp; Data Interchange</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2020</h4>
                  <p class="font-size-1">The Open API Economy: Finance-as-a-Service &amp; API Ecosystems</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Past year Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <div style="background-color:#ffffff;">
      <OrganiserBar :list="organiser_list" />
    </div>
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Sponsors Section ========== -->
    <div class="" style="background-color:#ffffff;">
      <div class="container space-top-2 space-bottom-1">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Selected Partners</span>
          <h2>Previous Partners and Sponsors Lineup</h2>
        </div>

        <div v-for="list in sponsor_list" :key="list.type" class="mb-9">
          <div class="text-center">
            <span class="divider mb-2 lead font-weight-bold">{{ list.type }}</span>
          </div>
          <div class="row text-center align-items-center" :class="list.css_class">
            <div v-for="sponsor in list.sponsor" :key="sponsor.image_url" class="col-12" :class="sponsor.css_class">
              <img class="img-fluid" :class="sponsor.image_css" :src="require('@/assets/img/apidays-2023/organizers/' + sponsor.image_url)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End sponsors Section ========== -->

    <!-- ========== Past Featured Press Section ========== -->
    <div style="background-color:#f5f9fc;">
      <div class="container space-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">Featured Press</h2>
          <p>Find out what the community says about our events.</p>
        </div>

        <div class="row mx-auto mb-5">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://unwire.pro/2023/09/04/benovelty-2/opendata/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/unwire-20230904.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <p class="font-size-1">Unwire.pro</p>
                  <h4>科技園與 beNovelty 攜手推動 API 技術產品化</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">September 04, 2023</p>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://itpromag.com/2023/08/31/hkstp-apidays-2023/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/itpro-20230831.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <p class="font-size-1">IT Pro Magazine</p>
                  <h4>香港科技園構建API生態系統 開放金融具發展潛力</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">Aug 31, 2023</p>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-0">
            <a class="card h-100 transition-3d-hover" href="https://www.hkstp.org/news-room/hkstp-and-benovelty-host-apidays-connect-hong-kong-2023-to-boost-digital-economy-development/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/hkstp-20230831.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <p class="font-size-1">HKSTP</p>
                  <h4>HKSTP and beNovelty Host Apidays Connect Hong Kong 2023 to Boost Digital Economy Development</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">August 31, 2023</p>
              </div>
            </a>
          </div>
        </div>

        <div class="col-12 text-center">
          <router-link class="btn btn-primary transition-3d-hover mt-3 mt-md-5" :to="{ path: '/press' }">View More</router-link>
        </div>
      </div>
    </div>
    <!-- ========== End Past Featured Press Section ========== -->

    <!-- Call for Speaker -->
    <!-- <div class="container space-2">
      <div class="card bg-primary text-white overflow-hidden p-4">
        <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
          <div class="col-md-6 offset-md-3 mb-3 mb-md-0">
            <h3 class="text-white mb-1">Interested to be Our Speakers?</h3>
            <p class="text-white-70 mb-3">Get connected now and join our speaker lineup to share your API expertise in apidays Hong Kong 2024!</p>
          </div>
          <div class="col-md-3 text-md-right">
            <a class="btn btn-light" href="https://app.moongate.id/events/apidays2023?utm_source=website&utm_medium=website" target="_blank">Be a Speaker</a>
          </div>
        </div>

        <figure class="w-25 d-none d-md-block content-centered-y ml-n4">
          <img class="img-fluid" src="@/assets/svg/illustrations/announcement.svg">
        </figure>

      </div>
    </div> -->

    <!-- ========== Registration Section========== -->
    <!-- <div id="registration" class="space-2 space-lg-3" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-6">
          <h2>Calling for Speakers & Sponsors!</h2>
          <p>Excited to be a part of our event? We're accepting applications for various roles! Simply fill out the forms below and let us know how you can contribute.</p>
        </div>
        <div class="row">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://forms.office.com/Pages/ResponsePage.aspx?id=LLfXUlCtHkGdT8oFy6wChyaOGdzghXZHl7uXgaoQPGZUQ1I2Qkw5TFZTSzJEVFZKUk84QUFCTDFDQy4u" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-7.svg">
                <h3>Speakers</h3>
                <p class="text-body">Apply now to become a featured speaker and share your expertise.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Apply Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://forms.office.com/Pages/ResponsePage.aspx?id=LLfXUlCtHkGdT8oFy6wChyaOGdzghXZHl7uXgaoQPGZURUgxWE5XQVlQS0hHU0FNTTJFM1lXSE0xVi4u" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-30.svg">
                <h3>Sponsors</h3>
                <p class="text-body">Partner with us as a sponsor and gain prominent visibility at our event.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#registerModal" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-48.svg">
                <h3>Audience</h3>
                <p class="text-body">Join us as participants and learn from the best API experts.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Register Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Registration Section========== -->

    <!-- ========== Subscription Section ========== -->
    <div class="container-fluid space-2" style="background-color:#ffffff;">
      <div class="container">
        <div class="text-center cta-bg" data-aos="fade-up">
          <h2>Be the First to Know</h2>
          <p>Subscribe to our e-newsletter to be the first to receive the latest API news and insights from the leading API Community.</p>
          <div class="w-md-90 w-lg-65 mx-auto form-subscribe mt-5">
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=002b9fe6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                <div class="row">
                  <div class="col col-md-6">
                    <!-- First name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <!-- Last name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                    </div>
                  </div>
                </div>
                <!-- Email -->
                <div class="mc-field-group w-100">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                </div>
                <div class="mb-4">
                  <p class="small text-center">By submitting your information, you agree to receive future communications from apidays.hk and OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden=""><input type="hidden" name="tags" value="2985272"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display: none;" />
                  <div id="mce-success-response" class="response" style="display: none;" />
                </div>
                <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0" value="Subscribe"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->

            <!-- <form ref="subscriptionForm" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body text-center">
            <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
            <h3>Replay Videos of apidays Hong Kong Conference Series</h3>
            <p>Don't miss out on this opportunity! Register below to gain immediate access and start watching the replay videos of our events.</p>
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=002b9fe6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                <div class="row">
                  <div class="col col-md-6">
                    <!-- First name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <!-- Last name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                    </div>
                  </div>
                </div>
                <!-- Email -->
                <div class="mc-field-group w-100">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                </div>
                <div class="mb-4">
                  <p class="small text-center">By submitting your information, you agree to receive future communications from apidays.hk and OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden=""><input type="hidden" name="tags" value="2985272"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>
                <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0" value="Subscribe"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../../assets/vendor/hs-video-player/src/js/hs-video-player'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.fancybox.js'
import '../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Apidays',
  components: {
    OrganiserBar
  },
  data() {
    return {
      isSubmitted: false,
      organiser_list: {
        title: 'Apidays Hong Kong',
        image_path: 'apidays-2023',
        css: 'space-2',
        organiser: [
          {
            post: 'Lead Organizer',
            image_url: 'benovelty.png',
            css: ''
          }
          // {
          //   post: 'Global Partner',
          //   image_url: 'apidays-2023.png',
          //   css: 'column-divider-md column-divider-20deg px-md-3'
          // }
        ]
      },
      sponsor_list: [
        {
          type: 'Global Partner',
          css_class: 'w-100 mx-auto',
          sponsor: [
            {
              css_class: 'col-12 col-lg-12 mb-12 mb-lg-12',
              image_css: 'max-w-27rem',
              image_url: 'apidays-2023.png'
            }
          ]
        },
        {
          type: 'Sponsors & Community Organizations',
          css_class: 'w-100 mx-auto mt-5',
          sponsor: [
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'alibaba-cloud.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'amadeus.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'atlassian-community.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'auth0.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'aws.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'confluent.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'dala.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'daops.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'fabrixapi.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'forgerock.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'hkcs.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'hkstp-saa.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'ibm.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'ifta.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'isoc.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'moongate.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'mulesoft.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'new-relic.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'nginx.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'on-us.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'openapihub.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'softwareag.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'suse.png'
            },
            {
              css_class: 'col-12 col-md-4 col-lg-3 mb-6',
              image_css: 'max-w-15rem',
              image_url: 'wso2.png'
            }

          ]
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  methods: {
    // loadScriptRecapSubscribeForm(url, callback) {
    //   var script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = url
    //   script.onreadystatechange = callback
    //   script.onload = callback
    //   this.$refs.recapSubscribeForm.appendChild(script)
    // }
    clearForm() {
      this.$refs.mcEmbeddedSubscribeForm.reset()
    }
  },
  metaInfo() {
    return {
      title: 'Apidays Connect Hong Kong 2023',
      titleTemplate: 'Apidays Connect Hong Kong 2023',
      link: [
        { rel: 'canonical', href: 'https://www.apidays.hk/' }
      ],
      meta: [
        { name: 'description', content: 'Apidays Connect Hong Kong 2023 — The API Landscape of Hong Kong' },
        { property: 'og:site_name', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>

